import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { useHistory} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./styles.css";
import {
  Card,
  Grid,
  Button,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, CardMedia } from "@mui/material";
import BackgroundImage from "./ccu-background.jpeg";
import Logo from './big-logo.png';

const useStyles = makeStyles({
  datepicker: {
    width: "300px",
    backgroundColor:"white !important",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },

    "& .MuiInputBase-root": {

      backgroundColor: "#ffffff !important",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    },

    '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
      /* '& fieldset': {            // - The <fieldset> inside the Input-root
          borderColor: 'pink',   // - Set the Input border
      }, */
      '&:hover fieldset': {
          borderColor: "#00B7C4", // - Set the Input border when parent has :hover
      }/* ,
      '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
          borderColor: 'green',
      }, */
  },

  }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = ({logOk}) => {
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tipoMensaje, setTipoMensaje] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { authenticate } = useContext(AccountContext);

  const classes = useStyles()

  const onSubmit = (event) => {
    event.preventDefault();

    authenticate(email, password)
      .then((data) => {
        setOpen(true);
        logOk(true);
        history.push('/inicio');
      })
      .catch((err) => {
        console.error("Failed to login", err);
        switch(String(err)){
          case "UserNotConfirmedException: User is not confirmed.":
            setTipoMensaje("error");
            setMensaje("No ha confirmado email");
            setOpen(true);
            break;
          case "NotAuthorizedException: Incorrect username or password.": 
            setTipoMensaje("error");
            setMensaje("Username o contraseña incorrecta");
            setOpen(true);
            break;
          case "InvalidParameterException: Missing required parameter": 
            setTipoMensaje("error");
            setMensaje("Falta el parámetro obligatorio");
            setOpen(true);
            break;
          case "NotAuthorizedException: Password attempts exceeded":
            setTipoMensaje("error");
            setMensaje("Se superaron los intentos de contraseña");
            setOpen(true);
            break;
          default:
            break;
        }
      });
  };

  const letrasStyle = { fontSize: "14.4px" , color: "#2f4554", textAlign:"center" };
  const btnstyle = { marginTop: "25px", marginBottom: "10px", backgroundColor: "#2f4554", textTransform: "none" };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div style={{
        backgroundImage:`url(${BackgroundImage})`,      
        height:'100vh',
        /* fontSize:'50px', */
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={12}>
            <Card style={{padding:"40px", margin:"10px"}}>
              <Grid align="center">
                {/* <Avatar style={avatarStyle}>
                  <LockOutlinedIcon style={{backgroundColor:"#2f4554"}} />
                </Avatar> */}

                <CardMedia
                  component="img"
                  image={Logo}
                  alt="Paella dish"
                  style={{ width: "100px"}}
                />

                <Typography
                  style={{
                    fontWeight: "bold",
                    marginTop: "15px",
                    marginBottom: "15px" ,
                    fontSize:"18px"
                  }}
                >
                  Bienvenido
                </Typography>

              <TextField
                className={classes.datepicker}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
                type="email"
                fullWidth
                required
                size="small"
              />
              <div style={{height:"10px"}}></div>
              <TextField
                className={classes.datepicker}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Contraseña"
                type="password"
                fullWidth
                required
                size="small"
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                onClick={onSubmit}
              >
                Ingresar
              </Button>
              <Typography>
                <Link href="/recuperarcontrasena" style={letrasStyle}>
                  ¿Olvidaste tu contraseña?
                </Link>
              </Typography>
              </Grid>
            </Card>   
          </Grid>      
        </Grid>


        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={tipoMensaje}
              sx={{ width: "100%" }}
            >
              {mensaje}
            </Alert>
          </Snackbar>
        </Stack>
      </div>
    </>
  );
};

export default Login;