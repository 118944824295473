import React, { useState, useContext, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {DialogActions, Grid, CardMedia} from "@mui/material";
import {AppBar,Toolbar,Typography,IconButton,} from "@material-ui/core";
import { AccountContext } from "../authentication/Account";
//import Store from "../context";
import NavDrawer from "./NavDrawer";
import { useHistory } from "react-router-dom";
import NavDrawerAdmin from "./NavDrawerAdmin";
import Button from "@mui/material/Button";
import { ExitToAppOutlined } from "@material-ui/icons";
//import {PersonIcon} from "@material-ui/icons";
import PersonIcon from "@material-ui/icons/Person";
import "./styles.css";
import ChangePassword from "../authentication/ChangePassword";
import LogoCCU from "../authentication/big-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
    fontSize: "14px"
  }
}));

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  
  "& .MuiDialog-paperWidthSm": {
    maxWidth: '800px'
}

}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
/*         <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */""
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const NavBar = () => {
  const [open, setOpen] = useState(false);
  //const { state, dispatch } = useContext(Store);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [role, setRole] = useState();
  const [userEmail, setUserEmail] = useState();

  const toggleDrawer = (booleanValue) => () => {
    setDrawerOpened(booleanValue);
  };

  const [status, setStatus] = useState(false);

  //console.log(AccountContext);

  const { getSession, logout } = useContext(AccountContext);

  // console.log(getSession)

  useEffect(() => {
    getSession().then((session) => {
      const { idToken } = session;
      const { payload } = idToken;
      const { email } = payload;
      try {
        setUserEmail(email);
        const [...userType] = payload["cognito:groups"];
        setRole(userType);
      } catch (err) {
        console.log(err);
      }
      setStatus(true);
    });
  }, [getSession]);

  const handleLogout = (e) => {
    //e.preventDefault();
    logout();
    setStatus(false);
    history.push("/login");
  };

  let varAux = role != undefined ? role.includes("admin") : false;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "#fff", color:"#2f4554" }}>
        <Toolbar>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={6} md={6} xs={1}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item lg={1} md={1} xs={1}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <Menu />
                </IconButton>
              </Grid>
              <Grid item lg={4} md={4} xs={4} sx={{display: { xs: "none", lg: "block", md:"block" }}}>
                <Typography variant="h1" className={classes.title}>
                  <div>{"SCHOP"}</div><div>{"INTELIGENTE"}</div>
                </Typography>
              </Grid>
              <Grid item lg={7} md={7} xs={7} sx={{display: { xs: "none", lg: "block", md:"block" }}}>
                <CardMedia
                  component="img"
                  image={LogoCCU}
                  alt="Logo CCU"
                  style={{ width: "60px"}}
                />
              </Grid>
              {/* <Typography variant="h2" style={{fontSize:"14.4px", color:"#2f4554"}}>
                Bienvenido
              </Typography> */}
            </Grid>
          </Grid>

          <Grid item lg={6} md={6} xs={11}>
            <Grid container spacing={4} alignItems="center" justifyContent="flex-end">

              <Grid item>
                <Button 
                  className={`navbarButton`}
                  style={{fontSize:"14.4px", textTransform:"lowercase"}}
                  onClick={handleClickOpen}
                  startIcon={
                    <PersonIcon  
                    />
                  } 
                >
                  {userEmail}
                </Button>


              </Grid>

              <Grid item>
                <Button
                  onClick={handleLogout}
                  className={`navbarButton`}
                  size="large"
                  startIcon={<ExitToAppOutlined />}
                  style = {{textTransform:"capitalize", fontSize:"14.4px"}}
                >
                  Cerrar sesión
                </Button>
              </Grid>

            </Grid>
          </Grid>

        </Grid>

          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Actualizar Datos de Usuario
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <ChangePassword />
              {/* <ChangeEmail /> */}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cerrar
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </Toolbar>
      </AppBar>
      {varAux ? (
        <NavDrawerAdmin
          drawerOpened={drawerOpened}
          toggleDrawer={toggleDrawer}
        />
      ) : (
        <NavDrawer drawerOpened={drawerOpened} toggleDrawer={toggleDrawer} />
      )}
    </div>
  );
};
export default NavBar;
