import React, {useState,useEffect} from 'react';
import NavBar from '../../components/NavBar';
import PropTypes from 'prop-types';
import moment from "moment";
import {getConsumoHorario, getClientes, getConsumoPorCliente} from "../../services";
import {
	Breadcrumbs, 
	Link, 
	Grid, 
	Container, 
	Card, 
  CardHeader,
	CardContent,
	Typography,
	TextField,
  CardMedia,
  CircularProgress
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "moment/locale/es";
import FiltroIcon from '@mui/icons-material/FilterAlt';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { 
  StyledAutocomplete, 
  StyledButton,
  StyledTextField,
  AlertButton
} from '../../components/MuiStyledComponents';

import { makeStyles } from '@mui/styles';

import LogoHeineken from '../../assets/images/logo-heineken.png';
import LogoEscudo from '../../assets/images/logo-escudo.png';
import LogoAustral from '../../assets/images/logo-austral.png';
import LogoDolbek from '../../assets/images/logo-dolbek.png';
import LogoKunstmann from '../../assets/images/logo-kunstmann.png';
import LogoRoyal from '../../assets/images/logo-royal.png';
import TotalIcon from '@mui/icons-material/Brightness4';
import DiurnoIcon from '@mui/icons-material/Brightness5';
import NocturnoIcon from '@mui/icons-material/Brightness3';

import KpiCard2 from '../../components/KpiCard2';
import ConsumoCategoricoChart from '../../components/ConsumoCategoricoChart';
import ToggleButtons from '../../components/ToggleButtons';
import InsightDonutChart from "../../components/InsightDonutChart.js";

import { es } from "date-fns/locale";

const useStyles = makeStyles({
  title1:{
    fontSize:"15px !important",
    fontWeight:"bold !important",
    color: "#2f4554"
  },
  title2:{
    fontSize:"15px !important",
    fontWeight:"bold !important",
    color: "#59AD30"
  },
  subtitle:{
    fontSize:"15px !important",
    color:"#606060"
  },
  inputLabel: {
    color:"white",
    fontSize:"14.4px !important"
  }
});

const DatosCliente = () => {
  const classes = useStyles();
  const consumoCargando = {
		labels: ["Cargando..."],
		volumenes: [1],
		porcentajes: [0]
	};
  const consumoVacio = {
		labels: ["No hay datos"],
		volumenes: [1],
		porcentajes: [0]
	};
  const consumoEjemplo =   {
    labels: [
        "Heineken",
        "Dolbek Maqui",
        "Torobayo",
        "Calafate"
    ],
    volumenes: [
        20.249,
        0.167,
        14.624,
        28.462
    ],
    porcentajes: [
        31.9,
        0.3,
        23.0,
        44.8
    ]
  }
  const [fechaInicio, setFechaInicio] = useState(new Date("2022-03-01 00:00:00"));
  const [fechaFin, setFechaFin] = useState(new Date());
  const [litrosTotales, setLitrosTotales] = useState(0);
  const [maxBeer, setMaxBeer] = useState("");
  const [minBeer, setMinBeer] = useState("");
  const [ordenLineas, setOrdenLineas] = useState("max");

  const [consumoTotal, setConsumoTotal] = useState(/* consumoEjemplo */consumoCargando);
  const [consumoDiurno, setConsumoDiurno] = useState(/* consumoEjemplo */consumoCargando);
  const [consumoNocturno, setConsumoNocturno] = useState(/* consumoEjemplo */consumoCargando);

  const defCliente = {
    id: 0,
    rut: "-",
    nombre: "Cargando ...",
    direccion: "-",
    id_distrito: "-",
    cant_lineas: 0
  }
  const [opcionesClientes, setOpcionesClientes] = useState([defCliente]);
  const [seleccionCliente, setSeleccionCliente] = useState(defCliente);

  const [cliente, setCliente] = useState(defCliente);

  const [loading, setLoading] = useState(true);
  const [loadingConsumoCliente,setLoadingConsumoCliente] = useState(true);

  
  /* Grafico barras Inicio */
  const [marcas, setMarcas] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [seriesPorLinea, setSeriesPorLinea] = useState([]);

  const objectToMatrix = (obj) => {
    //cambiar para ordenar lista de objetos
    let matrix = [];
    for (let keyObj in obj) {
        matrix.push([keyObj, obj[keyObj]]);
    }
    return matrix;
  }

  const sortMatrix2D = (matrix) => {
    matrix.sort(function(a, b) {
      return b[1] - a[1];
    }); 

    return matrix;
  }

  const getMaxMin = (list, group, type) => {
    let grouped={};
    list.forEach(dato => {
      const keyGroup = dato[group];
      if(!Boolean(grouped[keyGroup])) grouped[keyGroup]=0;
      grouped[keyGroup]+=Number(dato.litros);
    })

    const sortedGrouped = sortMatrix2D(objectToMatrix(grouped));

    switch(String(type)){
      case "max":
        return sortedGrouped[0][0]
      case "min":
        return sortedGrouped[(sortedGrouped.length)-1][0]
      default:
        break
    }
  }

  const sortObjArray = (arr,key) => {
    arr.sort(function (a, b) {
      if (Number(b[key]) > Number(a[key])) {
        return 1;
      }
      if (Number(b[key]) < Number(a[key])) {
        return -1;
      }
      return 0;
    });
  }

  const getLimitsList = (list, nCol, orden) => {
    const len = list.length;
    if(nCol>len) nCol=len;
    switch(String(orden)){
      case "max":
        return list.slice(0,nCol)
      case "min":
        let minList=[]
        for(let i=(len-1);i>=(len-nCol);i--){
          minList.push(list[i])
        }
        return minList
      default:
        return list.slice(0,nCol)
    }
  }

  const processData = ({lineas}) => {
    sortObjArray(lineas,"litros")

    let litrosTotales = 0;
    let marcas = [];
    let tipos = [];
    let valores = [];
    lineas.forEach(({nombre,marca,litros}) => {
      litros = Number(litros);
      litrosTotales+=litros;
      marcas.push(marca);
      tipos.push(nombre);
      valores.push(litros);
    })

    setMaxBeer(getMaxMin(lineas, "marca", "max"));
    setMinBeer(getMaxMin(lineas, "marca", "min"));
    setLitrosTotales(litrosTotales);
    setMarcas(marcas);
    setLineas(tipos);
    setSeriesPorLinea(valores);
    setLoadingConsumoCliente(false);

  }

  /* Grafico barras Fin */


  const getLegendColor = (index) =>{
    const colors=["#59AD31","#FEB31A","#134E32","#3A7B05","#FDBF41","#208254","#3C641B","70B84D"];
    const lastColorIndex=colors.length-1;
    if(index>lastColorIndex){
      return colors[lastColorIndex];
    }
    else{
      return colors[index];
    }
  }

  const getLogo = (id) => {
    const logos = {
      escudo: LogoEscudo,
      royal_guard: LogoRoyal,
      heineken: LogoHeineken,
      kunstmann: LogoKunstmann,
      dolbek: LogoDolbek,
      austral: LogoAustral,
    }
    return logos[id.toLowerCase().replace(" ","_")]
  }

  const sumArray = (arr) => {
		if(arr.length === 0){
			return 0;
		}
		else{
			const reducer = (accumulator, curr) => accumulator + curr;
			return arr.reduce(reducer).toFixed(1);
		}
	} 

  const getProcessChartData = (datosLinea) => {
		let labels = [];
		let volumenes = [];
		let volumenTotal = 0;
		datosLinea.forEach( (data,id) => {
			const datos = data[1];
			const volumen = datos.cantidad_ml/1000;
			const label = datos.linea;
			volumenes.push(volumen);
			volumenTotal+=volumen;
			labels.push(label);
		});

		let porcentajes = [];
		volumenes.forEach( vol => {
			porcentajes.push(((vol/volumenTotal)*100).toFixed(1));
		});

		return {
			labels: labels,
			volumenes: volumenes,
			porcentajes: porcentajes
		};

	};

  const processTimeData = ({total,diurno,nocturno}) => {
		const lineasTotal = total?Object.entries(total):[];
		const lineasDiurno = diurno?Object.entries(diurno):[];
		const lineasNocturno = nocturno?Object.entries(nocturno):[];

		setConsumoTotal(getProcessChartData(lineasTotal));
		setConsumoDiurno(getProcessChartData(lineasDiurno));
		setConsumoNocturno(getProcessChartData(lineasNocturno));
	}

  const callGetDatosHorarios = (id,inicio,fin) => {
    setConsumoTotal(consumoCargando);
    setConsumoDiurno(consumoCargando);
    setConsumoNocturno(consumoCargando);
		const res = getConsumoHorario(id,moment(inicio).format('YYYY-MM-DD'),moment(fin).format('YYYY-MM-DD'));
		res.then(({success,data}) => {if(success) processTimeData(data)});
  }

  const callGetConsumoPorCliente = (id,inicio,fin) => {
    setMarcas([]);
    setLineas([]);
    setSeriesPorLinea([]);
    setLoadingConsumoCliente(true);
		const res = getConsumoPorCliente(id,moment(inicio).format('YYYY-MM-DD'),moment(fin).format('YYYY-MM-DD'));
		res.then(({success,data}) => {if(success) processData(data)});
  }

  const callGetClientes = () => {
		const res = getClientes();
		res.then(({success,data}) => {
      if(success){
        setOpcionesClientes(data.clientes);
        setSeleccionCliente(data.clientes[0]);
        setCliente(data.clientes[0]);

        callGetDatosHorarios(data.clientes[0].id,fechaInicio,fechaFin);
        callGetConsumoPorCliente(data.clientes[0].id,fechaInicio,fechaFin);

        setLoading(false);
      }
    });
  }

  useEffect(()=>{
    callGetClientes();
  },[])

  const onClickBuscarCliente = () => {
    setCliente(seleccionCliente);
    callGetDatosHorarios(seleccionCliente.id,fechaInicio,fechaFin);
    callGetConsumoPorCliente(seleccionCliente.id,fechaInicio,fechaFin);
  }

  const onClickVerPorFecha = () => {
    callGetDatosHorarios(cliente.id,fechaInicio,fechaFin);
    callGetConsumoPorCliente(cliente.id,fechaInicio,fechaFin);
  }

  return(
    <>
      <NavBar/>
      <Container maxWidth="xl">
        <br/>
        <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
            href="/"
          >
            Schop Inteligente
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
          >
            {"Datos por Cliente"}
          </Link>
        </Breadcrumbs>
        <br/>
        <Typography variant="h1" className={classes.title1}>
          DATOS POR CLIENTE
        </Typography>

        <br/>

        <Card style={{backgroundColor:"#2f4554"}}>
          <CardContent style={{padding:"20px"}}>
            <Grid container alignItems="flex-end" justifyContent="space-between">
              <Grid item lg={10} md={10} xs={12}>
                <Grid container alignItems="flex-end" justifyContent="flex-start">
                  <Grid item lg={1} md={1} xs={12}>
                    <Typography className={classes.inputLabel}>
                      {"Buscar"}
                    </Typography>
                    <Typography className={classes.inputLabel}>
                      {"Cliente"}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.inputLabel}>
                      ID Territorio
                    </Typography>
                    <StyledAutocomplete
                      disabled
                      /* value={filtroDistrito} */
                      size="small"
                      disablePortal
                      ListboxProps={{
                        sx: { fontSize: "14.4px" },
                      }}
                      sx={{
                        /* width: 250, */
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                          fontSize: "14.4px",
                        },
                      }}
                      id="combo-box-demo"
                      options={[]}
                      renderInput={(params) => <StyledTextField {...params}/>}
                      /* getOptionLabel={option => option.name} */
                      /* onChange={(_event, dist) => {
                        setFiltroDistrito(dist);
                      }} */
                      disableClearable           
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.inputLabel}>
                      ID Cliente
                    </Typography>
                    <StyledAutocomplete
                      disabled
                      /* value={filtroMarca} */
                      size="small"
                      disablePortal
                      ListboxProps={{
                        sx: { fontSize: "14.4px" },
                      }}
                      sx={{
                        /* width: 250, */
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                          fontSize: "14.4px",
                        },
                      }}
                      id="combo-box-demo"
                      options={[]}
                      renderInput={(params) => <StyledTextField {...params}/>}
                      /* getOptionLabel={option => option.name} */
                      /* onChange={(_event, marca) => {
                        setFiltroMarca(marca);
                      }} */
                      disableClearable           
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.inputLabel}>
                      Nombre Local
                    </Typography>
                    <StyledAutocomplete
                      value={seleccionCliente}
                      size="small"
                      disablePortal
                      ListboxProps={{
                        sx: { fontSize: "14.4px" },
                      }}
                      sx={{
                        /* width: 250, */
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                          fontSize: "14.4px",
                        },
                      }}
                      id="combo-box-demo"
                      options={opcionesClientes}
                      renderInput={(params) => 
                        <StyledTextField 
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress color="inherit" size={20} style={{marginRight:"25px"}} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      }
                      getOptionLabel={option => option.nombre}
                      onChange={(_event, item) => {
                        setSeleccionCliente(item);
                      }}
                      disableClearable           
                    />
                  </Grid>       
                </Grid>
              </Grid>      
              <Grid item lg={2} md={12} xs={12}>
                <Grid container alignItems="flex-end" justifyContent="flex-end">
                  <Grid item>
                    <StyledButton onClick={()=>{onClickBuscarCliente()}}>Buscar</StyledButton>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </CardContent>
        </Card>

        <br/>

        <Card>
          <CardContent style={{padding:"0px"}}>
            <Grid container alignItems="flex-end" justifyContent="space-between">
              <Grid item lg={6} md={12} xs={12} style={{backgroundColor:"#fff", padding:"20px"}}>
                <Grid container alignItems="flex-end" spacing={1}>
                  <Grid item lg={9} md={9} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >  
                      <Grid item>
                        <Typography className={classes.title2} variant="h1">
                          {cliente.nombre}
                        </Typography>
                      </Grid>
                      <br/>
                      <Grid item variant="h2">
                        <Typography className={classes.subtitle}>
                          {cliente.direccion}
                        </Typography>
                      </Grid>
                      <Grid item variant="h2">
                        <Typography className={classes.subtitle}>
                          {`ID Cliente ${cliente.id} / ID Territorio ${cliente.id_distrito}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <AlertButton>Ver Alertas</AlertButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} md={12} xs={12} style={{backgroundColor:"#2f4554", padding:"20px"}}>
                <Grid container alignItems="flex-end" spacing={1} style={{minHeight:"90px"}}>
                  <Grid item lg={5} md={12} xs={12}>
                    <Typography className={classes.inputLabel}>
											Mostrar desde
										</Typography>
                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={fechaInicio}
                        onChange={(newValue) => {
                          setFechaInicio(newValue)
                        }}
                        renderInput={(params) => <StyledTextField  {...params} size="small" />}                    
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={5} md={12} xs={12}>
                    <Typography className={classes.inputLabel}>
											Hasta
										</Typography>
                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={fechaFin}
                        onChange={(newValue) => {
                          setFechaFin(newValue)
                        }}
                        renderInput={(params) => <StyledTextField  {...params} size="small" />}                    
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={2} md={12} xs={12}>
                    <Grid container alignItems="flex-end" justifyContent="flex-end">
                      <Grid item>
                      <StyledButton onClick={()=>{onClickVerPorFecha()}}>Ver</StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      
        <br/>

        <Typography variant="h1" className={classes.title1}>
          KPI's
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          Cliente
        </Typography>

        <br/>

        <Grid container spacing={2}>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Total Litros de Cerveza Consumida"
              subtitulo=""
              valor={litrosTotales.toFixed()}
              unidad="Lt"
              selected={true}
              flecha={false}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Promedio de Frescura"
              subtitulo="Días barril pinchado en línea"
              valor={0}
              unidad="días"
              flecha={true}
              selected={false}
            />          
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Porcentaje de Frescura"
              subtitulo="Promedio de frescrura por barril"
              valor={0}
              unidad="%"
              flecha={true}
              selected={false}
            />          
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Promedio Líneas Inactivas"
              subtitulo="Días continuos de línea inactiva"
              valor={0}
              unidad="días"
              flecha={true}
              selected={false}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Marca con Mayor Rotación"
              subtitulo="Cerveza más consumida"
              valor=""
              unidad=""
              logo={getLogo(maxBeer)}
              flecha={false}
              selected={false}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Marca con Menor Rotación"
              subtitulo="Cerveza menos consumida"
              valor=""
              unidad=""
              logo={getLogo(minBeer)}
              flecha={false}
              selected={false}
            />
          </Grid>
        </Grid>

        <br/>
        <br/>

        <Grid container>
          <Grid item lg={5} md={5} xs={12}>
            <Typography variant="h1" className={classes.title1}>
              Litros Totales Consumidos
            </Typography>
            <Typography variant="h2" className={classes.subtitle}>
              Por Línea
            </Typography>
          </Grid>
          <Grid item lg={7} md={7} xs={12}>
            <ToggleButtons
              buttons={[
                {value: "max" ,label:"Mayor consumo"},
                {value: "min" ,label:"Menor consumo"}
              ]}
              onSelect={setOrdenLineas}
            />           
          </Grid>
        </Grid>

        <br/>

        <Card>
          <CardContent>
            <ConsumoCategoricoChart
              series={[{
                name: 'Volumen',
                data: getLimitsList(seriesPorLinea, 6, ordenLineas)
              }]}
              categorias={getLimitsList(lineas, 6, ordenLineas)}    
              loading={loadingConsumoCliente}            
            />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{marginLeft:"5%",paddingRight:"50px"}}
            >
              {(getLimitsList(marcas, 6, ordenLineas)).map( (marca,index) =>{
                const largo = (getLimitsList(marcas, 6, ordenLineas)).length;
                const espacio = Number((12/largo).toFixed(0));
                return(
                  <Grid item lg={espacio} md={espacio} xs={espacio} sx={{display: { xs: "none", lg: "block", md:"block" }}} key={index}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CardMedia
                        component="img"
                        image={getLogo(marca)}
                        alt="Paella dish"
                        style={{ width: "90px"}}
                      />
                    </Grid>
                  </Grid>
              )})}
            </Grid>

          </CardContent>
        </Card>

        <br/>

				<Card>
          <CardContent style={{padding:"0px"}}>
            <Grid container style={{padding:"24px 15px"}}>
              {/* Total */}
              <Grid item lg={4} md={4} xs={12}>							
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item lg={5}>
                    <InsightDonutChart 
                      volumenes={consumoTotal.volumenes} 
                      labels={consumoTotal.labels} 
                      porcentajes={consumoTotal.porcentajes}
                    />
                  </Grid>
                  <Grid item lg={7}>
                    <Grid container justifyContent="center">
                      <Grid item lg={12}>
                        <TotalIcon style={{color:"#4d4d4d"}}/>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#2f4554"}}>
                          Consumo total
                        </Typography>
                        <Typography style={{fontSize:"14.4px", color:"#4d4d4d",marginBottom:"10px"}}>
                          Todas las horas
                        </Typography>
                      </Grid>
                      {consumoTotal.labels.map((name,index)=>{
                        return(								
                          <Grid key={index} item lg={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item lg={4}>
                                <Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#2f4554"}}>
                                  {`${consumoTotal.volumenes[index].toFixed(1)} lts`}
                                </Typography>
                              </Grid>
                              <Grid item lg={8}>
                                <Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
                                  {`${name} (${consumoTotal.porcentajes[index]}%)`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid item lg={12} style={{marginTop:"10px"}}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >                  
                          <Grid item lg={4}>
                            <Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#2f4554"}}>
                              {`${sumArray(consumoTotal.volumenes)} lts`}
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                      <Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#2f4554"}}>
                          {`Total (100%)`}
                        </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
              {/* Diurno */}
              <Grid item lg={4} md={4} xs={12}>							
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item lg={5}>
                    <InsightDonutChart 
                      volumenes={consumoTotal.volumenes} 
                      labels={consumoTotal.labels} 
                      porcentajes={consumoTotal.porcentajes}
                    />
                  </Grid>
                  <Grid item lg={7}>
                    <Grid container justifyContent="center">
                      <Grid item  lg={12}>
                        <DiurnoIcon style={{color:"#4d4d4d"}}/>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#2f4554"}}>
                          Consumo Diurno
                        </Typography>
                        <Typography style={{fontSize:"14.4px", color:"#4d4d4d",marginBottom:"10px"}}>
                          {"07:01 - 18:59"}
                        </Typography>
                      </Grid>
                      {consumoDiurno.labels.map((name,index)=>{
                        return(								
                          <Grid item lg={12} key={index}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item lg={4}>
                                <Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#2f4554"}}>
                                  {`${consumoDiurno.volumenes[index].toFixed(1)} lts`}
                                </Typography>
                              </Grid>
                              <Grid item lg={8}>
                                <Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
                                  {`${name} (${consumoDiurno.porcentajes[index]}%)`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid item lg={12} style={{marginTop:"10px"}}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >                  
                          <Grid item lg={4}>
                            <Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#2f4554"}}>
                              {`${sumArray(consumoDiurno.volumenes)} lts`}
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                      <Typography style={{fontSize:"14.4px",fontWeight:"bold", color:"#2f4554"}}>
                          {`Total (100%)`}
                        </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>

              {/* Nocturno */}
              <Grid item lg={4} md={4} xs={12}>							
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >

                  <Grid item lg={5}>
                    <InsightDonutChart 
                      volumenes={consumoTotal.volumenes} 
                      labels={consumoTotal.labels} 
                      porcentajes={consumoTotal.porcentajes}
                    />
                  </Grid>
                  <Grid item lg={7}>
                    <Grid container justifyContent="center">
                      <Grid item lg={12}>
                        <NocturnoIcon style={{color:"#4d4d4d"}}/>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#2f4554"}}>
                          Consumo Nocturno
                        </Typography>
                        <Typography style={{fontSize:"14.4px", color:"#4d4d4d",marginBottom:"10px"}}>
                          {"19:00 - 07:00"}
                        </Typography>
                      </Grid>
                      {consumoNocturno.labels.map((name,index)=>{
                        return(								
                          <Grid item lg={12} key={index}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid item lg={4}>
                                <Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#2f4554"}}>
                                  {`${consumoNocturno.volumenes[index].toFixed(1)} lts`}
                                </Typography>
                              </Grid>
                              <Grid item lg={8}>
                                <Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
                                  {`${name} (${consumoNocturno.porcentajes[index]}%)`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid item lg={12} style={{marginTop:"10px"}}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >                  
                          <Grid item lg={4}>
                            <Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#2f4554"}}>
                              {`${sumArray(consumoNocturno.volumenes)} lts`}
                            </Typography>
                          </Grid>
                          <Grid item lg={8}>
                      <Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#2f4554"}}>
                          {`Total (100%)`}
                        </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
            </Grid>

            <div style={{backgroundColor:"#2f4554", padding:"24px"}}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <span style={{color:"#fff", fontWeight:"bold"}}>Cerveza</span>
                </Grid>

                {consumoNocturno.labels.map((item,index)=>{
                  return(
                    <Grid item key={index}>
                      <div>
                        <span 
                          style={{
                            margin:"0px 5px 0px 25px",
                            height:"13px",
                            width:"13px",
                            backgroundColor:getLegendColor(index),
                            borderRadius:"50%",
                            display: "inline-block"
                          }}>
                        </span>
                        <span style={{color:"#fff"}}>{item}</span>
                      </div>
                    </Grid>
                  )
                })}
              </Grid> 
            </div>
          </CardContent>
        </Card>

        <br/>

      </Container>
    </>
  )
}

export default DatosCliente;