import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
//import HomeIcon from '@mui/icons-material/Home';
//import WhatshotIcon from '@mui/icons-material/Whatshot';
//import GrainIcon from '@mui/icons-material/Grain';

var moment = require("moment-timezone");

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "id_vibot",
    headerName: "ID Vibot",
    width: 150,
  },
  {
    field: "id_sensor",
    headerName: "ID Sensor",
    width: 150,
  },
  {
    field: "ml_cantidad",
    headerName: "Cantidad ML",
    type: "number",
    width: 170,
  },
  {
    field: "seg_duracion",
    headerName: "Seg. Duración",
    type: "number",
    width: 170,
  },
  {
    field: "hora_inicio",
    headerName: "Fecha",
    width: 170,
    valueFormatter: ({ value }) =>
      moment(value).utc().format("DD/MM/YYYY HH:mm:ss"),
  },
];

const TableCCU = (props) => {
  const { loading, rows } = props;

  return !loading ? (
    <>
      <CssBaseline />
      <Container>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "20px" }}>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="#"
          >
            {/* <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="#"
          >
            {/* <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
            Dashboard
          </Link>
        </Breadcrumbs>
        <div style={{ height: 550, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={40}
            rowsPerPageOptions={[40]}
            density={"compact"}
            //checkboxSelection
            disableSelectionOnClick
          />
        </div>
      </Container>
    </>
  ) : (
    "Loading..."
  );
};
export default TableCCU;
