import React, { useState, useEffect, useContext } from "react";
import AWS from "aws-sdk";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import NavBar from "./NavBar";
import Dashboard from "../views/Dashboard";
import { AccountContext } from "../authentication/Account";
import SimpleBackdrop from "./BackDrop";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
//import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const Tablausuario = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const { getSession } = useContext(AccountContext);
  const [role, setRole] = useState();

  useEffect(() => {
    getSession().then((session) => {
      const { idToken } = session;
      const { jwtToken, payload } = idToken;
      const { email, email_verified } = payload;
      try {
        const [...userType] = payload["cognito:groups"];

        setRole(userType);
      } catch (err) {
        console.log(err);
      }

      setLoggedIn(true);
    });
  }, [loggedIn, getSession]);

  const getUsers = async () => {
    try {
      let allUsers = [];
      let more = true;
      let paginationToken = "";

      while (more) {
        let params = {
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Limit: 60,
        };
        if (paginationToken !== "") {
          params.PaginationToken = paginationToken;
        }

        AWS.config.update({
          region: process.env.REACT_APP_USER_POOL_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_KEY,
        });
        const cognito = new AWS.CognitoIdentityServiceProvider();
        const rawUsers = await cognito.listUsers(params).promise();
        allUsers = allUsers.concat(rawUsers.Users);

        if (rawUsers.PaginationToken) {
          paginationToken = rawUsers.PaginationToken;
        } else {
          more = false;
        }
      }

      let email_Usuarios;
      if (allUsers[0] !== undefined) {
        email_Usuarios = allUsers.map((x) => {
          const { Username, Attributes } = x;
          return {
            id: Username,
            id_borrar: Username,
            sub: Attributes[0].Value,
            phone_number: Attributes[3]===undefined?"":Attributes[3].Value,
            given_name: Attributes[4]===undefined?"":Attributes[4].Value,
            middle_name: Attributes[5]===undefined?"":Attributes[5].Value,
            family_name: Attributes[6]===undefined?"":Attributes[6].Value,
          };
        });
      }

      setUsers(email_Usuarios);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUser = async (user2delete) => {
    try {
      let more = true;
      let paginationToken = "";

      while (more) {
        let params = {
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Limit: 60,
        };
        if (paginationToken !== "") {
          params.PaginationToken = paginationToken;
        }

        AWS.config.update({
          region: process.env.REACT_APP_USER_POOL_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_KEY,
        });
        const cognito = new AWS.CognitoIdentityServiceProvider();

        const resultDetelete = await cognito
          .adminDeleteUser({
            UserPoolId: process.env.REACT_APP_USER_POOL_ID,
            Username: user2delete,
          })
          .promise();

        const { $response } = resultDetelete;
        const { httpResponse } = $response;
        const { statusCode } = httpResponse;

        if (statusCode == 200) {
          getUsers();
        }
      }
    } catch (e) {
      //console.log(e);
    }
  };

  useEffect((user2delete) => {
    deleteUser(user2delete);
  }, []);

  const handlePurge = (e) => {
    setRows();
  };

  const columns = [
    { field: "id", headerName: "Username", width: 200 },
    {
      field: "given_name",
      headerName: "Primer Nombre",
      width: 200,
      renderCell: (params) => {
        const{row}=params
        return row.given_name
      },
    },
    {
      field: "middle_name",
      headerName: "Segundo Nombre",
      width: 200,
      renderCell: (params) => {
        const{row}=params
        return row.middle_name
      },
    },
    {
      field: "family_name",
      headerName: "Apellidos",
      width: 200,
      renderCell: (params) => {
        const{row}=params
        return row.family_name
      },
    },
    {
      field: "phone_number",
      headerName: "Teléfono",
      width: 200,
      renderCell: (params) => {
        const{row}=params
        return row.phone_number
      },
    },
    {
      field: "id_borrar",
      headerName: "Acción",
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            style={{
              borderRadius: 18,
              backgroundColor: "#58b062",
              padding: "18px 36px",
            }}
            variant="contained"
            color="primary"
            onClick={handlePurge}
          >
            Borrar
          </Button>
        );
      },
    },

  ];

  const handleRowSelection = (e) => {
    const { id, row } = e;
    const { id_borrar, sub } = row;
    var result = window.confirm("Estas seguro que lo quieres borrar?");
    if (result) {
      deleteUser(id_borrar);
    }
  };

  let varAux = role != undefined ? role.includes("admin") : false;

  return (
    <>
      {loggedIn && varAux ? (
        <div style={{ height: 550, width: "100%" }}>
          <NavBar />
          {loading ? (
            <SimpleBackdrop />
          ) : (
            <>
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="#"
                >
                  {/* <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="#"
                >
                  {/* <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
                  Administrar usuarios
                </Link>
              </Breadcrumbs>

              <DataGrid
                rows={rows != undefined ? rows : users}
                columns={columns}
                pageSize={40}
                rowsPerPageOptions={[40]}
                density={"compact"}
                checkboxSelection
                //disableSelectionOnClick
                onCellClick={handleRowSelection}
              />
            </>
          )}
        </div>
      ) : (
        <Dashboard />
      )}{" "}
    </>
  );
};

export default Tablausuario;
