import React, { useContext, useReducer} from "react";
import ReactDOM from "react-dom";
import { Account } from "./authentication/Account";
import Store from "./context";
import reducer from "./reducer";
import { usePersistedContext, usePersistedReducer } from "./usePersist";

// Metarial-UI Theme(Dark or Light)
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@material-ui/core";
import Main from "./components/Main";
//import Pool from "./UserPool";

import { createStore } from "redux";
import { Provider} from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";

/* VSC Comment */

const App = () => {
  //const user = Pool.getCurrentUser();
  const globalStore = usePersistedContext(useContext(Store), "state");

  const [state, dispatch] = usePersistedReducer(
    useReducer(reducer, globalStore),
    "state"
  );

  const theme = createTheme({
    palette: {
      type: state.myTheme, // "light" or "dark"
      secondary: {
        main: "#2f4554",
      },
      primary: {
        main: "#59ad30",
      },
    },
  });

  // store
  const store = createStore(rootReducer, composeWithDevTools());

  return (
    <Provider store={store}>
      <Store.Provider value={{ state, dispatch }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Account>
            <Main/>
          </Account>
        </ThemeProvider>
      </Store.Provider>
    </Provider>
  );
};
ReactDOM.render(<App />, document.querySelector("#root"));
