import {React} from "react";
import Chart from "react-apexcharts";

const ConsumoCategoricoChart = ({series,categorias,loading}) => {

  const options = {
    noData: {
      text: loading?"Cargando ..." : "No hay datos",
      offsetX: 70,
      offsetY: -20,
      style: {
        color: "#2f4554"/* "#00b7c4" */,
        fontSize: '14.4px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      }
    },
    grid: {
      position: 'front'
    },
    legend:{
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 10
    },
    colors:["#59AD31","#FEB31A","#134E32","#3A7B05","#FDBF41","#208254","#3C641B","70B84D"],
    chart: {
      toolbar:{show:false},
      defaultLocale: 'en',
      locales: [{
        name: 'en',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      }],
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        columnWidth: `${categorias.length*13}%` /* '80%' */,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return `${val.toFixed(1)} Lt`
      },
      offsetY: -1000,
      style: {
        fontSize: '11px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: undefined,
        colors: ["#000000"]
    },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: categorias,
      labels:{
        style: {
          fontWeight: "bold",
      },
      }
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return `${Math.floor(val)} Lt`
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val} lts`
        }
      }
    }
  };
  
  return (
    <div id="chartbar">
      <Chart height={"250px"} width={"100%"} options={options} series={series} type="bar" />
    </div>
  );
  
}

export default ConsumoCategoricoChart;