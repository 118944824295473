import {React} from "react";
import Chart from "react-apexcharts";

const ConsumoDiarioBarChart = ({series,fechas}) => {
  const options = {
    legend:{
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 10
    },
    colors:["#59AD31","#FEB31A","#134E32","#3A7B05","#FDBF41","#208254","#3C641B","70B84D"],
    chart: {
      defaultLocale: 'en',
      locales: [{
        name: 'en',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      }],
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      type: 'datetime',
      categories: fechas,
      labels: {
        format: 'ddd dd',
        style: {
        }
      },
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return Math.floor(val)
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val} lts`
        }
      }
    }
  };
  
  return (
    <div id="chartbar">
      <Chart height={"250px"} width={"100%"} options={options} series={series} type="bar" />
    </div>
  );
  
}

export default ConsumoDiarioBarChart;