import {React} from 'react';
import { CircularProgress } from '@mui/material';

const LoadingScreen = () => {
	return(
		<div style={{backgroundColor:"#fff"}}>
			<div style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center", marginTop:"25%"
			}}>
				<CircularProgress style = {{color: "#2f4554"}} size = {100}/>
			</div>
		</div>
	)
}

export default LoadingScreen;
