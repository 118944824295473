import * as React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import { StyledToggleButton } from './MuiStyledComponents';

const useStyles = makeStyles({
  toggle:{
    ".Mui-selected":{backgroundColor:"red"}
    /* textTransform:"none !important"   */
  }
});

export default function ToggleButtons({buttons,onSelect}) {
  const classes = useStyles();
  const [alignment, setAlignment] = React.useState(buttons[0].value);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    onSelect(newAlignment);
  };

  return (
    <ToggleButtonGroup
      size="small"
      color="secondary"
      value={alignment}
      exclusive
      onChange={handleChange}
      className={classes.toggle}
    >
      {
        buttons.map((button,index) => {
          return(
            <StyledToggleButton 
              sx={{textTransform:"none", pointerEvents:alignment===button.value?"none":"auto"}} 
              value={button.value}
              key={index}
            >
              {button.label}
            </StyledToggleButton>
          )
        })
      }
    </ToggleButtonGroup>
  );
}