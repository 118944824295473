export const getConsumoDiario = async (id, inicio, fin) => {
  try {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    let rawBody = JSON.stringify({
      id_cliente: id,
      fecha_inicio: inicio,
      fecha_fin: fin
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawBody,
      redirect: "follow",
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}get-consumo-diario`,
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
};

export const getConsumoHorario = async (id, inicio, fin) => {
  try {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    let rawBody = JSON.stringify({
      id_cliente: id,
      fecha_inicio: inicio,
      fecha_fin: fin
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawBody,
      redirect: "follow",
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}get-consumo-horario`,
      requestOptions
    );

    /* if (!res.ok) throw new Error(res.text()); */
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
};

export const getDatosTotales = async (inicio, fin) => {
  try {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    let rawBody = JSON.stringify({
      fecha_inicio: inicio,
      fecha_fin: fin
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawBody,
      redirect: "follow",
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}get-consumo-clientes`,
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
};

export const getConsumoPorCliente = async (id, inicio, fin) => {
  try {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    let rawBody = JSON.stringify({
      id_cliente: id,
      fecha_inicio: inicio,
      fecha_fin: fin
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: rawBody,
      redirect: "follow",
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}get-consumo-cliente`,
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
};

export const getClientes = async () => {
  try {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}cliente`,
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
};
