import React, {useState,useEffect} from 'react';
import NavBar from '../../components/NavBar';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer  } from 'react-big-calendar';
import moment from "moment";
import InsightDonutChart from "../../components/InsightDonutChart.js";
import ConsumoDiarioBarChart from "./ConsumoDiarioBarChart";
import {getConsumoDiario, getConsumoHorario} from "../../services";
import {
	Breadcrumbs, 
	Link, 
	Grid, 
	Container, 
	Card, 
	CardContent,
	CardHeader,
	Typography,
	Autocomplete,
	TextField,
	InputAdornment
} from "@mui/material";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./ReactBigCalendar.css";
import "moment/locale/es";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import KpiCard from './KpiCard';
import InsightsIcon from '@mui/icons-material/Insights';
import TotalIcon from '@mui/icons-material/Brightness4';
import DiurnoIcon from '@mui/icons-material/Brightness5';
import NocturnoIcon from '@mui/icons-material/Brightness3';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import FiltroIcon from '@mui/icons-material/FilterAlt';

import { StyledAutocomplete } from '../../components/MuiStyledComponents';

const DetalleCliente = ({idCliente,nombreCliente}) => {
	const consumoVacio = {
		labels: ["Cargando..."],
		volumenes: [1],
		porcentajes: [0]
	};

	const defaultSeries={
		"0": {
			name: 'Cargando...',
			data: [0]
		}
	};

	const defaultLinea = {id:0, name:"Todas las líneas"}
	const [consumoDiario, setConsumoDiario] = useState({});
	const [consumoTotal, setConsumoTotal] = useState(consumoVacio);
	const [consumoDiurno, setConsumoDiurno] = useState(consumoVacio);
	const [consumoNocturno, setConsumoNocturno] = useState(consumoVacio);
	
	const [seriesPorId, setSeriesPorId] = useState(defaultSeries);
	const [fechas, setFechas] = useState([]);

	const [lineaSeleccionada, setLineaSeleccionada] = useState(defaultLinea);
	const [opcionesLineas, setOpcionesLineas] = useState([defaultLinea]);

	const defFechaInicio = new Date();
	const defFechaFin = new Date();
	defFechaInicio.setDate(defFechaInicio.getDate() - 15);
	const [fechaInicio, setFechaInicio] = useState(defFechaInicio);
	const [fechaFin, setFechaFin] = useState(defFechaFin);

	const sumArray = (arr) => {
		if(arr.length === 0){
			return 0;
		}
		else{
			const reducer = (accumulator, curr) => accumulator + curr;
			return arr.reduce(reducer).toFixed(1);
		}
	} 

	const getFilterSeries = (id, series) => {
		if(Number(id)===0){
			return Object.values(series);
		}
		else{
			return [series[id]];
		}
	}

	const getConsumoDiarioPromedio = (id,series) => {
		if(Number(id)===0){
			let contSeries=0;
			let contPromedios=0;
			for(let serie of Object.values(series)){
				const arrayDatos = serie.data;
				contPromedios+=sumArray(arrayDatos)/arrayDatos.length;
				contSeries+=1;
			}
			return (contPromedios/contSeries).toFixed(1);
		}
		else{
			const arrayDatos = series[id].data;
			return (sumArray(arrayDatos)/arrayDatos.length).toFixed(1);
		}
	};

	const getProcessChartData = (datosLinea) => {
		let labels = [];
		let volumenes = [];
		let volumenTotal = 0;
		datosLinea.forEach( (data,id) => {
			const datos = data[1];
			const volumen = datos.cantidad_ml/1000;
			const label = datos.linea;
			volumenes.push(volumen);
			volumenTotal+=volumen;
			labels.push(label);
		});

		let porcentajes = [];
		volumenes.forEach( vol => {
			porcentajes.push(((vol/volumenTotal)*100).toFixed(1));
		});

		return {
			labels: labels,
			volumenes: volumenes,
			porcentajes: porcentajes
		};

	};

	const processTimeData = (data) => {
		const lineasTotal = Object.entries(data.total);
		const lineasDiurno = Object.entries(data.diurno);
		const lineasNocturno = Object.entries(data.nocturno);

		setConsumoTotal(getProcessChartData(lineasTotal));
		setConsumoDiurno(getProcessChartData(lineasDiurno));
		setConsumoNocturno(getProcessChartData(lineasNocturno));
	}

	const processDailyData = (data) => {
		const fechas = Object.keys(data);
		const ids = Object.keys(data[fechas[0]]);

		let series = {};
		let listaOpciones = [defaultLinea];
		for(let id of ids){
			const name = data[fechas[0]][id].linea;
			listaOpciones.push({id:id,name:name});

			let seriesLinea = {
				name: name ,
				data: []
			}
			for(let fecha of fechas){
				seriesLinea.data.push(data[fecha][id].cantidad_ml/1000);
			}
			series[id] = seriesLinea;
		}

		setOpcionesLineas(listaOpciones);
		setSeriesPorId(series);
		setFechas(fechas.map(fecha=>fecha.split("-").reverse().join("-")));


	}; 
	useEffect(()=>{
		const consumoDiarioRes = getConsumoDiario(idCliente, moment(fechaInicio).format('YYYY-MM-DD'),moment(fechaFin).format('YYYY-MM-DD'));
		consumoDiarioRes.then(({success,data}) => {if(success) processDailyData(data)});

		const consumoHorarioRes = getConsumoHorario(idCliente,moment(fechaInicio).format('YYYY-MM-DD'),moment(fechaFin).format('YYYY-MM-DD'));
		consumoHorarioRes.then(({success,data}) => {if(success) processTimeData(data)});
	},[idCliente]);

	const formats = {
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
  }

	const localizer = momentLocalizer(moment);

	let eventoEjemplo = [{
		id: 0,
		title: 
			<div 
				title = {`${19} lts A.calafate \n ${19} lts A.calafate \n ${19} lts A.calafate \n ${19} lts A.calafate`}
			>
				<div style={{display: "flex",  width: "100%"}}>
					<div style={{width:"65%", fontWeight:"bold"}}>
						{`${19} lts`}
					</div>
					<div>
						{`A. Calafate`}
					</div>
				</div>
				<div style={{display: "flex",  width: "100%"}}>
					<div style={{width:"65%", fontWeight:"bold"}}>
						{`${19} lts`}
					</div>
					<div>
						{`A. Calafate`}
					</div>
				</div>
				<div style={{display: "flex",  width: "100%"}}>
					<div style={{width:"65%", fontWeight:"bold"}}>
						{`${19} lts`}
					</div>
					<div>
						{`A. Calafate`}
					</div>
				</div>
				<div style={{display: "flex",  width: "100%"}}>
					<div style={{width:"65%", fontWeight:"bold"}}>
						{`${19} lts`}
					</div>
					<div>
						{`A. Calafate`}
					</div>
				</div>
			</div>,
		start: new Date()/* "2022-05-15 00:00:00" */,
		end: new Date()/* "2022-05-15 23:00:00" */

	}]

	return(
		<>
			<NavBar/>
			<Container maxWidth="xl">
				<br/>
				<Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
					<Link
						underline="hover"
						sx={{ display: "flex", alignItems: "center" }}
						style={{fontSize:"14.4px"}}
						color="inherit"
						href="/"
					>
						Schop Inteligente
					</Link>
					<Link
						underline="hover"
						sx={{ display: "flex", alignItems: "center" }}
						style={{fontSize:"14.4px"}}
						color="inherit"
						href="/inicio"
					>
						{"Clientes"}
					</Link>

					<Link
						underline="hover"
						sx={{ display: "flex", alignItems: "center" }}
						style={{fontSize:"14.4px", color:"#59AD30"}}
						color="inherit"
					>
						{`ID ${idCliente}`}
					</Link>
				</Breadcrumbs>

				<br/>

				<Card>
					<CardContent style={{padding:"20px"}}>
						{/* <Grid
							container
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
						>
							<Grid item>
								{`Mostrando ${lineaSeleccionada.name} - ${"2022-03-01"} al ${"2022-05-01"}`}
							</Grid>
						</Grid> */}
						<Grid container>
							<Grid item lg={5} md={5} xs={12}>
								<Grid container>
									<Grid item lg={12} md={12} xs={12}>
										<Typography  style={{fontSize:"16px", fontWeight:""}}>
											Cliente	
										</Typography>
									</Grid>
									<Grid item lg={12} md={12} xs={12}>
											<span style={{fontSize:"16px", fontWeight:"bold"}}>
												{nombreCliente}
											</span>
											<span style={{fontSize:"16px", fontWeight:"bold", color:"#59ad30"}}>
												{` / ID ${idCliente}`}
											</span>
									</Grid>
								</Grid>
							</Grid>
							<Grid item lg={7} md={7} xs={12}>
														<Grid
							container
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
						>
							<Grid item>
								<span style={{fontSize:"14.4px",color:"#59ad30", fontStyle:"italic"}}>
									Mostrando 
								</span>
								<span style={{fontSize:"14.4px",fontStyle:"italic"}}>
								  {` ${lineaSeleccionada.name} / ${moment(fechaInicio).format('YYYY-MM-DD')} al ${moment(fechaFin).format('YYYY-MM-DD')}`}
								</span>
							</Grid>
						</Grid>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Grid item>
										<StyledAutocomplete
											value={lineaSeleccionada}
											size="small"
											disablePortal
											ListboxProps={{
												sx: { fontSize: "14.4px" },
											}}
											sx={{
												width: 250,
												'& .MuiAutocomplete-input, & .MuiInputLabel-root': {
													fontSize: "14.4px",
												},
											}}
											id="combo-box-demo"
											options={opcionesLineas}
											renderInput={(params) => <TextField {...params}
											
											/* InputProps={{
												startAdornment:(
													<InputAdornment position="start">
														<FiltroIcon/>
													</InputAdornment>
												)
											}} *//>}
											getOptionLabel={option => option.name}
											onChange={(_event, linea) => {
												setLineaSeleccionada(linea);
											}}
											disableClearable
										
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<br/>

				<Grid
					container
					spacing={2}
				>
					<Grid item lg={6} md={6} xs={12}>
						<KpiCard
							name="Índice de Frescura Promedio"
							value={6}
							unit="días por barril"
							icon={<AcUnitIcon sx={{color:"white", fontSize:"40px"}}/>}
						/>
					</Grid>

					<Grid item lg={6} md={6} xs={12}>
						<KpiCard
							name="Consumo Diario Promedio"
							value={getConsumoDiarioPromedio(lineaSeleccionada.id, seriesPorId)}
							unit="litros diarios"
							icon={<SportsBarIcon sx={{color:"white", fontSize:"40px"}}/>}
						/>
					</Grid>
				</Grid>

				<br/>

				<Card>
					<CardHeader
						sx={{borderBottom:"1px solid rgba(0, 0, 0, 0.1)", padding:"0"}}
						title={
							<Grid container spacing={2} sx={{margin:"0"}}>
								<Grid item>
									<CalendarIcon/>
								</Grid>
								<Grid item>
									<Typography /* className={classes.titulo} */ style={{fontSize:"14.4px", fontWeight:"bold"}}>
										Consumo diario
									</Typography>
								</Grid>
							</Grid>
						}
					/>
					<CardContent>
							<ConsumoDiarioBarChart
								series={getFilterSeries(lineaSeleccionada.id, seriesPorId)}
								fechas={fechas}
							/>
					</CardContent>
				</Card>

				<br/>

				<Card>
					<CardHeader
						sx={{borderBottom:"1px solid rgba(0, 0, 0, 0.1)", padding:"0"}}
						title={
							<Grid container spacing={2} sx={{margin:"0"}}>
								<Grid item>
									<InsightsIcon/>
								</Grid>
								<Grid item>
									<Typography /* className={classes.titulo} */ style={{fontSize:"14.4px", fontWeight:"bold"}}>
										Insight del Consumidor
									</Typography>
								</Grid>
							</Grid>
						}
					/>
					<CardContent>
						<Grid container>
							{/* Total */}
							<Grid item lg={4} md={4} xs={12}>							
								<Grid
									container
									direction="column"
									justifyContent="center"
									alignItems="center"
								>
									<Grid item>
										<InsightDonutChart 
											volumenes={consumoTotal.volumenes} 
											labels={consumoTotal.labels} 
											porcentajes={consumoTotal.porcentajes}
											showLegend
										/>
									</Grid>
									<Grid item>
										<Grid container spacing={1}>
											<Grid item>
												<TotalIcon style={{color:"#4d4d4d"}}/>
											</Grid>
											<Grid item>
												<Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#3A7B05"}}>
													Consumo total
												</Typography>
												<Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
													Todas las horas
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									{consumoTotal.labels.map((name,index)=>{
										return (									
											<Grid item>
												<Grid
													container
													direction="row"
													justifyContent="flex-start"
													alignItems="center"
													spacing={2}
												>
													<Grid item>
														<div style={{borderRadius:"25px", padding:"1px 5px",  backgroundColor:(lineaSeleccionada===name?"#ffd47e":"")}}>
															<Typography style={{fontSize:"14.4px", fontWeight:"bold"}}>
																{`${consumoTotal.volumenes[index].toFixed(1)} lts`}
															</Typography>
														</div>
													</Grid>
													<Grid item>
														<div style={{borderRadius:"25px", padding:"1px 5px",  backgroundColor:(lineaSeleccionada.name===name?"#ffd47e":"")}}>
															<Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
																{`${name} (${consumoTotal.porcentajes[index]}%)`}
															</Typography>
														</div>
													</Grid>
												</Grid>
											</Grid>
										)
									})}

									<br/>

									<Grid item lg={12} md={12}>
										<Grid
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={2}
										>
											<Grid item>
												<Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#3A7B05"}}>
													{`${sumArray(consumoTotal.volumenes)} lts`}
												</Typography>
											</Grid>
											<Grid item>
											<Typography style={{fontSize:"14.4px", color:"#3A7B05"}}>
													{`Total (100%)`}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

								</Grid>

							</Grid>
							{/* Diurno */}
							<Grid item lg={4} md={4} xs={12}>							
								<Grid
									container
									direction="column"
									justifyContent="center"
									alignItems="center"
								>
									<Grid item>
										<InsightDonutChart 
											volumenes={consumoDiurno.volumenes} 
											labels={consumoDiurno.labels} 
											porcentajes={consumoDiurno.porcentajes}
											showLegend
										/>
									</Grid>
									<Grid item>
										<Grid container spacing={1}>
											<Grid item>
												<DiurnoIcon style={{color:"#4d4d4d"}}/>
											</Grid>
											<Grid item>
												<Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#3A7B05"}}>
														Consumo diurno
												</Typography>
												<Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
													07:01 - 18:59
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									{consumoDiurno.labels.map((name,index)=>{
											return (
												<Grid item>
													<Grid
														container
														direction="row"
														justifyContent="flex-start"
														alignItems="center"
														spacing={2}
													>
														<Grid item>
															<div style={{borderRadius:"25px", padding:"1px 5px",  backgroundColor:(lineaSeleccionada.name===name?"#ffd47e":"")}}>
																<Typography style={{fontSize:"14.4px", fontWeight:"bold"}}>
																	{`${consumoDiurno.volumenes[index].toFixed(1)} lts`}
																</Typography>
															</div>
														</Grid>
														<Grid item>
															<div style={{borderRadius:"25px", padding:"1px 5px",  backgroundColor:(lineaSeleccionada.name===name?"#ffd47e":"")}}>
																<Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
																	{`${name} (${consumoDiurno.porcentajes[index]}%)`}
																</Typography>
															</div>
														</Grid>
													</Grid>
												</Grid>
											)
									})}

									<br/>

									<Grid item lg={12} md={12}>
										<Grid
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={2}
										>
											<Grid item>
												<Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#3A7B05"}}>
													{`${sumArray(consumoDiurno.volumenes)} lts`}
												</Typography>
											</Grid>
											<Grid item>
											<Typography style={{fontSize:"14.4px", color:"#3A7B05"}}>
													{`Total (100%)`}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

								</Grid>

							</Grid>

							{/* Nocturno */}
							<Grid item lg={4} md={4} xs={12}>							
								<Grid
									container
									direction="column"
									justifyContent="center"
									alignItems="center"
								>
									<Grid item>
										<InsightDonutChart 
											volumenes={consumoNocturno.volumenes} 
											labels={consumoNocturno.labels} 
											porcentajes={consumoNocturno.porcentajes}
											showLegend
										/>
									</Grid>
									<Grid item>
										<Grid container spacing={1}>
											<Grid item>
												<NocturnoIcon style={{color:"#4d4d4d"}}/>
											</Grid>
											<Grid item>
												<Typography style={{fontSize:"14.4px", fontWeight:"bold", color:"#3A7B05"}}>
														Consumo nocturno
												</Typography>
												<Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
													19:00 - 07:00
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									{consumoNocturno.labels.map((name,index)=>{
											return ( 									
												<Grid item>
													<Grid
														container
														direction="row"
														justifyContent="flex-start"
														alignItems="center"
														spacing={2}
													>
														<Grid item>
															<div style={{borderRadius:"25px", padding:"1px 5px",  backgroundColor:(lineaSeleccionada.name===name?"#ffd47e":"")}}>
																<Typography style={{fontSize:"14.4px", fontWeight:"bold"}}>
																	{`${consumoNocturno.volumenes[index].toFixed(1)} lts`}
																</Typography>
															</div>
														</Grid>
														<Grid item>
															<div style={{borderRadius:"25px", padding:"1px 5px",  backgroundColor:(lineaSeleccionada.name===name?"#ffd47e":"")}}>
																<Typography style={{fontSize:"14.4px", color:"#4d4d4d"}}>
																	{`${name} (${consumoNocturno.porcentajes[index]}%)`}
																</Typography>
															</div>
														</Grid>
													</Grid>
												</Grid>
											)
									})}

									<br/>

									<Grid item lg={12} md={12}>
										<Grid
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											spacing={2}
										>
											<Grid item>
												<Typography style={{fontSize:"14.4px", fontWeight:"bold",color:"#3A7B05"}}>
													{`${sumArray(consumoNocturno.volumenes)} lts`}
												</Typography>
											</Grid>
											<Grid item>
												<Typography style={{fontSize:"14.4px", color:"#3A7B05"}}>
													{`Total (100%)`}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

								</Grid>

							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<br/>

				{/* <Calendar
					formats={formats}
					selectable={true}
					toolbar={false}
					localizer={localizer}
					events={eventoEjemplo}
					startAccessor="start"
					endAccessor="end"
					date={new Date()}
					style = {{height:"1000px"}}
					scrollToTime
					/> */}

			</Container>
		</>
	)
}

DetalleCliente.propTypes = {
  idCliente: PropTypes.number.isRequired,
	nombreCliente: PropTypes.string.isRequired
}

DetalleCliente.defaultProps = {
	idCliente: 1926231,
	nombreCliente: "La Cantina"
}

export default DetalleCliente;

