import React, { useEffect, useContext, useState } from "react";
import { AccountContext } from "../authentication/Account";
import NavBar from "../components/NavBar";
import TableCCU from "../components/TableCCU";
import Main from "../components/Main";
import SimpleBackdrop from "../components/BackDrop";

const Dashboard = () => {
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const select_registro_vibot = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/registro-vibot`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRows(result);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getSession().then((data) => {
      setLoggedIn(true);
    });
    select_registro_vibot();
  }, [loggedIn, loading]);

  return !loading ? (
    <div className="settings">
      {loggedIn ? (
        <>
          <NavBar />
          <TableCCU rows={rows} loading={loading} />
        </>
      ) : (
        <Main />
      )}
    </div>
  ) : (
    <SimpleBackdrop />
  );
};

export default Dashboard;
