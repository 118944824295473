import {
  Divider, Drawer,
  List, ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';


const useStyles = makeStyles({
  list: {
    width: 240,
  },
  info: {
    "text-align": "center",
    "font-weight": "bold",
  },
});

const NavDrawerAdmin = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      anchor="left"
      open={props.drawerOpened}
      onClose={props.toggleDrawer(false)}
    >
      <div
        className={classes.list}
        //onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
        style={{height:"100%",backgroundColor:"#2f4554", color:"white", padding:"25px"}}
      >
        <List>
          <ListItem button component={Link} to="/datos-totales" >
            <ListItemIcon style={{color:"#fff", minWidth:"40px"}}>
              <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary="Datos totales" />
          </ListItem>
        </List>

        <List>
          <ListItem button component={Link} to="/datos-cliente" >
            <ListItemIcon style={{color:"#fff", minWidth:"40px"}}>
              <StoreIcon/>
            </ListItemIcon>
            <ListItemText primary="Datos por Cliente" />
          </ListItem>
        </List>

        <List>
          <ListItem button component={Link} to="/register">
            <ListItemText primary="Registrar Usuarios" />
          </ListItem>
        </List>

        <List>
          <ListItem button component={Link} to="/tablausuario">
            <ListItemText primary="Administrar Usuarios" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};
export default NavDrawerAdmin;
