import {React} from "react";
import Chart from "react-apexcharts";

const InsightDonutChart = ({volumenes,labels,showLegend,porcentajes}) => {
  const series =  volumenes;
  const options = {
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val.toFixed(1)} lts`
        }
      }
    },
    plotOptions: {
      pie: {
        donut:{
          size: '35%',
        }
      }
    },
    legend:{
      show: showLegend
    },
    labels: labels,
    colors:["#59AD31","#FEB31A","#134E32","#3A7B05","#FDBF41","#208254","#3C641B","70B84D"],
    chart: {
      type: 'donut'
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        blur: 3,
        opacity: 0.8
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        }
      }
    }]
  }
  
  return (
    <div id="chart">
      <Chart height={"200px"} options={options} series={series} type="donut" />
    </div>
  );
  
}

export default InsightDonutChart;