import {React} from 'react';
import NavBar from '../../components/NavBar';
import DatosTotales from '../datos-totales';

const Inicio = () => {
	return(
		<>
			<DatosTotales/>
		</>
	)
}

export default Inicio;