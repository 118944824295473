import React, {useState,useEffect} from 'react';
import NavBar from '../../components/NavBar';
import PropTypes from 'prop-types';
import moment from "moment";
import {getDatosTotales} from "../../services";
import {
	Breadcrumbs, 
	Link, 
	Grid, 
	Container, 
	Card, 
	CardContent,
	Typography,
	TextField,

  CardMedia
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "moment/locale/es";
import FiltroIcon from '@mui/icons-material/FilterAlt';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { 
  StyledAutocomplete, 
  StyledButton,
  StyledTextField
} from '../../components/MuiStyledComponents';

import { makeStyles } from '@mui/styles';
import KpiCard2 from '../../components/KpiCard2';
import ConsumoCategoricoChart from '../../components/ConsumoCategoricoChart';
import ToggleButtons from '../../components/ToggleButtons';

import LogoHeineken from '../../assets/images/logo-heineken.png';
import LogoEscudo from '../../assets/images/logo-escudo.png';
import LogoAustral from '../../assets/images/logo-austral.png';
import LogoDolbek from '../../assets/images/logo-dolbek.png';
import LogoKunstmann from '../../assets/images/logo-kunstmann.png';
import LogoRoyal from '../../assets/images/logo-royal.png';

import { es } from "date-fns/locale";

const useStyles = makeStyles({
  title1:{
    fontSize:"15px !important",
    fontWeight:"bold !important",
    color: "#2f4554"
  },
  subtitle:{
    fontSize:"15px !important"  
  },
  inputLabel: {
    color:"white",
    fontSize:"14.4px !important"
  }
});

const DatosTotales = () => {
  const classes = useStyles();
  const [seriesPorMarca, setSeriesPorMarca] = useState([]);
  const [seriesPorLocal, setSeriesPorLocal] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [locales, setLocales] = useState([]);

  const defFiltroMarca = "Todas las marcas";
  const defFiltroDistrito = "Todos los distritos";
  const [opcionesMarca, setOpcionesMarca] = useState([defFiltroMarca]);
  const [opcionesDistrito, setOpcionesDistrito] = useState([defFiltroDistrito]);
  const [filtroMarca, setFiltroMarca] = useState(defFiltroMarca);
  const [filtroDistrito, setFiltroDistrito] = useState(defFiltroDistrito);

  const [ordenMarcas, setOrdenMarcas] = useState("max");
  const [ordenLocales, setOrdenLocales] = useState("max");

  const [fechaInicio, setFechaInicio] = useState(new Date("2022-03-01 00:00:00"));
  const [fechaFin, setFechaFin] = useState(new Date());

  const [datosPorCliente, setDatosPorCliente] = useState([]);

  const [litrosTotales, setLitrosTotales] = useState(0);
  const [maxBeer, setMaxBeer] = useState("");
  const [minBeer, setMinBeer] = useState("");

  const objectToMatrix = (obj) => {
    //cambiar para ordenar lista de objetos
    let matrix = [];
    for (let keyObj in obj) {
        matrix.push([keyObj, obj[keyObj]]);
    }
    return matrix;
  }

  const sortMatrix2D = (matrix) => {
    matrix.sort(function(a, b) {
      return b[1] - a[1];
    }); 

    return matrix;
  }

  const getMaxMin = (list, group, type) => {
    let grouped={};
    list.forEach(dato => {
      const keyGroup = dato[group];
      if(!Boolean(grouped[keyGroup])) grouped[keyGroup]=0;
      grouped[keyGroup]+=dato.litros;
    })
    const sortedGrouped = sortMatrix2D(objectToMatrix(grouped));

    switch(String(type)){
      case "max":
        return sortedGrouped[0][0]
      case "min":
        return sortedGrouped[(sortedGrouped.length)-1][0]
      default:
        break
    }
  }

  
  useEffect(()=>{
    if(datosPorCliente.length>0){
      setMaxBeer(getMaxMin(datosPorCliente,"marca","max"));
      setMinBeer(getMaxMin(datosPorCliente,"marca","min"));
    }
  },[datosPorCliente])

  const getSeries = (list, group, filtroMarca, filtroDistrito, setLabels, setValues) => {
    let filtrarMarca = false;
    let filtrarDistrito= false;

    if(filtroMarca!==defFiltroMarca) filtrarMarca=true;
    if(filtroDistrito!==defFiltroDistrito) filtrarDistrito=true;

    let seriesFiltradas={}
    list.forEach(dato => {
      const keyGroup = dato[group];
      if(!Boolean(seriesFiltradas[keyGroup])) seriesFiltradas[keyGroup]=0;

      if(filtrarMarca && filtrarDistrito){
        if((filtroMarca===dato.marca) && (filtroDistrito===dato.distrito)){
          seriesFiltradas[keyGroup]+=dato.litros;
        }
      }
      else if(filtrarMarca){
        if((filtroMarca===dato.marca)){
          seriesFiltradas[keyGroup]+=dato.litros;
        }
      }
      else if(filtrarDistrito){
        if((filtroDistrito===dato.distrito)){
          seriesFiltradas[keyGroup]+=dato.litros;
        }
      }
      else{
        seriesFiltradas[keyGroup]+=dato.litros;
      }

    });

    const seriesOrdenadas = sortMatrix2D(objectToMatrix(seriesFiltradas));
    
    let labels=[]
    let values=[]
    seriesOrdenadas.forEach((item)=>{
      if(item[1]>0){
        labels.push(item[0]);
        values.push(item[1]);
      }
    })

    setLabels(labels);
    setValues(values);
  }

  const processData = (data) => {
    let clientes=[]
    let distritos=[defFiltroDistrito]
    let marcas=[defFiltroMarca]
    let datosPorCliente=[]
    let litrosTotales = 0;
    data.forEach(({id,nombre,distrito,lineas}) => {
      const local = nombre;
      distrito = String(distrito);
      if(!distritos.includes(distrito)) distritos.push(distrito);
      if(!clientes.includes(local)) clientes.push(local);
      lineas.forEach(({nombre,marca,litros}) => {
        litros = Number(litros);
        if(marca === filtroMarca || filtroMarca === defFiltroMarca) litrosTotales+=litros;
        if(!marcas.includes(marca)) marcas.push(marca);
        datosPorCliente.push({
          idLocal: id,
          tipo: nombre,
          distrito: distrito,
          marca: marca,
          litros: litros,
          local: local
        })

      })
    });

    setLitrosTotales(litrosTotales);

    setOpcionesMarca(marcas);
    setOpcionesDistrito(distritos);
    setDatosPorCliente(datosPorCliente);
    getSeries(datosPorCliente, "local",filtroMarca, filtroDistrito, setLocales,setSeriesPorLocal)
    getSeries(datosPorCliente, "marca",filtroMarca, filtroDistrito,setMarcas,setSeriesPorMarca)
  }


  const callGetDatosTotales = () => {
    const res = getDatosTotales(moment(fechaInicio).format('YYYY-MM-DD'),moment(fechaFin).format('YYYY-MM-DD'));
		res.then(({success,data}) => {if(success) processData(data)});
  }

  useEffect(()=>{
    callGetDatosTotales()
  },[])

  const getLogo = (id) => {
    const logos = {
      escudo: LogoEscudo,
      royal_guard: LogoRoyal,
      heineken: LogoHeineken,
      kunstmann: LogoKunstmann,
      dolbek: LogoDolbek,
      austral: LogoAustral,
    }
    return logos[id.toLowerCase().replace(" ","_")]
  }

  const getLimitsList = (list, nCol, orden) => {
    const len = list.length;
    if(nCol>len) nCol=len;
    switch(String(orden)){
      case "max":
        return list.slice(0,nCol)
      case "min":
        let minList=[]
        for(let i=(len-1);i>=(len-nCol);i--){
          minList.push(list[i])
        }
        return minList
      default:
        return list.slice(0,nCol)
    }
  }

  return(
    <>
      <NavBar/>
			<Container maxWidth="xl">
        <br/>
				<Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
					<Link
						underline="hover"
						sx={{ display: "flex", alignItems: "center" }}
						style={{fontSize:"14.4px"}}
						color="inherit"
						href="/"
					>
						Schop Inteligente
					</Link>
					<Link
						underline="hover"
						sx={{ display: "flex", alignItems: "center" }}
						style={{fontSize:"14.4px"}}
						color="inherit"
					>
						{"Datos Totales"}
					</Link>
				</Breadcrumbs>

        <br/>

        <Typography variant="h1" className={classes.title1}>
          DATOS TOTALES
        </Typography>

        <br/>

        <Card style={{backgroundColor:"#2f4554"}}>
          <CardContent style={{padding:"20px"}}>
            <Grid container alignItems="flex-end" justifyContent="space-between">
              <Grid item lg={5} md={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes.inputLabel}>
											Mostrar desde
										</Typography>
                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={fechaInicio}
                        onChange={(newValue) => {
                          setFechaInicio(newValue)
                        }}
                        renderInput={(params) => <StyledTextField  {...params} size="small" />}                    
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes.inputLabel}>
											Hasta
										</Typography>
                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={fechaFin}
                        onChange={(newValue) => {
                          setFechaFin(newValue)
                        }}
                        renderInput={(params) => <StyledTextField  {...params} size="small" />}                    
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={5} md={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes.inputLabel}>
											Distrito
										</Typography>
                    <StyledAutocomplete
                      value={filtroDistrito}
                      size="small"
                      disablePortal
                      ListboxProps={{
                        sx: { fontSize: "14.4px" },
                      }}
                      sx={{
                        /* width: 250, */
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                          fontSize: "14.4px",
                        },
                      }}
                      id="combo-box-demo"
                      options={opcionesDistrito}
                      renderInput={(params) => <StyledTextField {...params}/>}
                      /* getOptionLabel={option => option.name} */
                      onChange={(_event, dist) => {
                        setFiltroDistrito(dist);
                      }}
                      disableClearable           
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography className={classes.inputLabel}>
											Marcas
										</Typography>
                    <StyledAutocomplete
                      value={filtroMarca}
                      size="small"
                      disablePortal
                      ListboxProps={{
                        sx: { fontSize: "14.4px" },
                      }}
                      sx={{
                        /* width: 250, */
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                          fontSize: "14.4px",
                        },
                      }}
                      id="combo-box-demo"
                      options={opcionesMarca}
                      renderInput={(params) => <StyledTextField {...params}/>}
                      /* getOptionLabel={option => option.name} */
                      onChange={(_event, marca) => {
                        setFiltroMarca(marca);
                      }}
                      disableClearable           
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={1} md={12} xs={12}>
                <StyledButton onClick={()=>{callGetDatosTotales()}}>Ver</StyledButton>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      
        <br/>

        <Typography variant="h1" className={classes.title1}>
          KPI's
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          Totales
        </Typography>

        <br/>

        <Grid container spacing={2}>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Total Litros de Cerveza Consumida"
              subtitulo=""
              valor={litrosTotales.toFixed()}
              unidad="Lt"
              selected={true}
              flecha={false}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Promedio de Frescura"
              subtitulo="Días barril pinchado en línea"
              valor={0}
              unidad="días"
              flecha={true}
              selected={false}
            />          
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Porcentaje de Frescura"
              subtitulo="Promedio de frescrura por barril"
              valor={0}
              unidad="%"
              flecha={true}
              selected={false}
            />          
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Promedio Líneas Inactivas"
              subtitulo="Días continuos de línea inactiva"
              valor={0}
              unidad="días"
              flecha={true}
              selected={false}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Marca con Mayor Rotación"
              subtitulo="Cerveza más consumida"
              valor=""
              unidad=""
              logo={getLogo(maxBeer)}
              flecha={false}
              selected={false}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <KpiCard2 
              titulo="Marca con Menor Rotación"
              subtitulo="Cerveza menos consumida"
              valor=""
              unidad=""
              logo={getLogo(minBeer)}
              flecha={false}
              selected={false}
            />
          </Grid>
        </Grid>

        <br/>
        <br/>

        <Grid container>
          <Grid item lg={5} md={5} xs={12}>
            <Typography variant="h1" className={classes.title1}>
              Consumo por Marca
            </Typography>
            <Typography variant="h2" className={classes.subtitle}>
              Litros consumidos por marca
            </Typography>
          </Grid>
          <Grid item lg={7} md={7} xs={12}>
            <ToggleButtons
              buttons={[
                {value: "max" ,label:"Mayor consumo"},
                {value: "min" ,label:"Menor consumo"}
              ]}
              onSelect={setOrdenMarcas}
            />           
          </Grid>
        </Grid>

        <br/>

        <Card>
          <CardContent>
            <ConsumoCategoricoChart
              series={[{
                name: 'Net Profit',
                data: getLimitsList(seriesPorMarca, 6, ordenMarcas)
              }]}
              categorias={getLimitsList(marcas, 6, ordenMarcas)}            
            />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{marginLeft:"5%",paddingRight:"50px"}}
            >
              {(getLimitsList(marcas, 6, ordenMarcas)).map( marca =>{
                const largo = (getLimitsList(marcas, 6, ordenMarcas)).length;
                const espacio = (12/largo).toFixed(0);
                return(
                  <Grid item lg={espacio} md={espacio} xs={espacio} sx={{display: { xs: "none", lg: "block", md:"block" }}}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CardMedia
                        component="img"
                        image={getLogo(marca)}
                        alt="Paella dish"
                        style={{ width: "90px"}}
                      />
                    </Grid>
                  </Grid>
              )})}
            </Grid>

          </CardContent>
        </Card>

        <br/>
        <br/>

        <Grid container>
          <Grid item lg={5} md={5} xs={12}>
            <Typography variant="h1" className={classes.title1}>
              Consumo por Local
            </Typography>
            <Typography variant="h2" className={classes.subtitle}>
              Locales con mayor y menor consumo
            </Typography>
          </Grid>
          <Grid item lg={7} md={7} xs={12}>
            <ToggleButtons
              buttons={[
                {value: "max" ,label:"Mayor consumo"},
                {value: "min" ,label:"Menor consumo"}
              ]}
              onSelect={setOrdenLocales}
            />           
          </Grid>
        </Grid>

        <br/>

        <Card>
          <CardContent>
            <ConsumoCategoricoChart
              series={
                [{
                  name: 'Net Profit',
                  data: getLimitsList(seriesPorLocal, 6, ordenLocales) 
                }]
              }
              categorias={getLimitsList(locales, 6, ordenLocales)}
            />
          </CardContent>

        </Card>

        <br/>

      </Container>
    </>
  )
}

export default DatosTotales;