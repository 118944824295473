import React, { useContext, useState, useEffect } from "react";
import Status from "../authentication/Status";
import Signup from "../authentication/Signup";
import Login from "../authentication/Login";
import Settings from "../authentication/Settings";
import { AccountContext } from "../authentication/Account";
//import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import ForgotPassword from "../authentication/ForgotPassword";
import Tablausuario from "./TablaUsuario";
import SimpleBackdrop from "./BackDrop";
import Dashboard from "../views/Dashboard";
import Inicio from "../views/inicio";
import DetalleCliente from "../views/detalle";
import LoadingScreen from "../utils/LoadingScreen";
import DatosTotales from "../views/datos-totales";
import DatosCliente from "../views/datos-cliente";

const Main = () => {
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loginOk, setLoginOk] = useState(false);
  //const history = useHistory();

  useEffect(() => {
    getSession().then(() => {
      setLoggedIn(true);
      setLoadingPage(false);
      /* history.push('/dashboard'); */
    })
    .catch(()=> {
      setLoadingPage(false);
    })
  }, [loginOk]);
  
  return (
    <>
      {
        loadingPage ? (
          <LoadingScreen/>
        )
        :(
          !loggedIn ? (
            <>
              <Status />
              <Login logOk = {setLoginOk}/>
              <Settings />
              <Router>
                <Switch>
                  <Route exact path="/recuperarcontrasena" component={ForgotPassword} />
                </Switch>
              </Router>
            </>
          ) 
          :(
            <>
              <Router>
                <Switch>
                  <Route exact path="/cliente" component={DetalleCliente}/>
                  {/* Rutas provisorias */}
                  <Route exact path="/cliente/one-pizza" 
                    render={()=><DetalleCliente idCliente = {1693623} nombreCliente = {"One Pizza"}/>}
                  />
                  <Route exact path="/cliente/chiloe-en-tu-mesa" 
                    render={()=><DetalleCliente idCliente = {2133855} nombreCliente = {"Chiloé en tu Mesa"}/>}
                  />
                  <Route exact path="/cliente/la-cantina" 
                    render={()=><DetalleCliente idCliente = {1926231} nombreCliente = {"La Cantina"}/>}
                  />                  
                  <Route exact path="/cliente/bar-de-niro" 
                    render={()=><DetalleCliente idCliente = {1441761} nombreCliente = {"Bar de Niro"}/>}
                  />
                  <Route exact path="/cliente/amapola" 
                    render={()=><DetalleCliente idCliente = {1424240} nombreCliente = {"Amapola"}/>}
                  />                                  
                  {/* Fin rutas provisorias */}
                  <Route exact path="/datos-totales" component = {DatosTotales}/>
                  <Route exact path="/login" component = {Main} />
                  <Route exact path="/register" component={Signup} />
                  <Route exact path="/recuperacontrasena" component={ForgotPassword} />
                  <Route exact path="/tablausuario" component={Tablausuario} />
                  <Route exact path="/actualizarusuario" component={Settings} />
                  <Route exact path="/backdrope" component={SimpleBackdrop} />
                  <Route exact path="/inicio" component={Inicio}/>
                  <Route exact path="/datos-cliente" component={DatosCliente}/>
                  <Route path="/" render={()=>(<Redirect to='/inicio'/>)}/>
                </Switch>
              </Router>
            </>
          )
        )
      }
    </>
  );
};

export default Main;
