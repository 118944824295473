import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { TextField, Button} from "@material-ui/core";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const { getSession } = useContext(AccountContext);
  const btnstyle = { margin: "8px 0", backgroundColor: "#005829" };

  //console.log(getSession)

  const onSubmit = (event) => {
    event.preventDefault();

    getSession().then(({ user }) => {
      user.changePassword(password, newPassword, (err, result) => {
        if (err) {
          console.error(err);
        } else {
          console.log(result);
        }
      });
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <TextField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          label="Contraseña actual"
          placeholder="CLAVE ACTUAL"
          type="password"
          fullWidth
          required
        />

        <TextField
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          label="Contraseña nueva"
          placeholder="NUEVA CLAVE"
          type="password"
          fullWidth
          required
        />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={onSubmit}
        >
          Cambiar clave
        </Button>

        {/*         <label>Current password</label>
        <input
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <label>New password</label>
        <input
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />

        <button type="submit">Change password</button> */}
      </form>
    </div>
  );
};

export default ChangePassword;