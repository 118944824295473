import { styled } from "@mui/material/styles";
import {
	Autocomplete,
  Button,
  TextField,
  ToggleButton
} from "@mui/material";

export const StyledTextField = styled(TextField)({
  ".MuiInputBase-root":{
    backgroundColor:"white",
    fontSize:"14.4px",
    width: "240px"
  },
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      paddingLeft: 6    
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#59ad30",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#59ad30"
    }
  }
});

export const StyledAutocomplete = styled(Autocomplete)({
  ".MuiInputBase-root":{
    backgroundColor:"white"
  },
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 6    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#59ad30",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#59ad30"
    }
  }
});

export const StyledButton = styled(Button)({
  backgroundColor: "#59AD30",
  textTransform: 'none',
  color: "white",
  padding: "7px 30px",
  "&:hover":{
    backgroundColor: "#59AD30",
    filter: "brightness(1.1)"
  }
});

export const AlertButton = styled(Button)({
  backgroundColor: "rgb(204,65,37)",
  textTransform: 'none',
  color: "white",
  padding: "7px 30px",
  "&:hover":{
    backgroundColor: "rgb(204,65,37,0.8)",
  }
});

export const StyledToggleButton = styled(ToggleButton)({
  "&.MuiToggleButton-root":{
    paddingLeft: "20px",
    paddingRight:"20px",
    backgroundColor:"#C1C7CB",
    border:"none",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  },
  "&.Mui-selected": {
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    backgroundColor: 'white',
    fontWeight:"bold"
  }
});
