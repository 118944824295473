import React, { useState, useContext, useEffect } from "react";
import NavBar from "../components/NavBar";
import UserPool from "../UserPool";
import { AccountContext } from "../authentication/Account";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Dashboard from "../views/Dashboard";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [primerNombre, setPrimerNombre] = useState("");
  const [segundoNombre, setSegundoNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [telefono, setTelefono] = useState("");
  const [role, setRole] = useState();
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    getSession().then((session) => {
      const { idToken } = session;
      const { jwtToken, payload } = idToken;
      const { email, email_verified } = payload;

      try {
        const [...userType] = payload["cognito:groups"];

        setRole(userType);
      } catch (err) {
        console.log(err);
      }

      setLoggedIn(true);
    });
  }, [loggedIn, getSession]);

  const onSubmit = (event) => {
    event.preventDefault();

    var poolData = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_CLIENT_ID,
    };

    var attributeList = [];
    var dataEmail = {
      Name: "email",
      Value: email,
    };

    var dataPhoneNumber = {
      Name: "phone_number",
      Value: telefono,
    };

    var dataFirstName = {
      Name: "given_name",
      Value: primerNombre,
    };

    var dataMiddleName = {
      Name: "middle_name",
      Value: segundoNombre,
    };

    var dataFamilyName = {
      Name: "family_name",
      Value: apellidos,
    };

    var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataEmail
    );
    var attributePhoneNumber = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataPhoneNumber
    );
    var attributeDataFirstName = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataFirstName
    );
    var attributeDataMiddleName =
      new AmazonCognitoIdentity.CognitoUserAttribute(dataMiddleName);
    var attributeDataFamilyName =
      new AmazonCognitoIdentity.CognitoUserAttribute(dataFamilyName);
    attributeList.push(attributeEmail);
    attributeList.push(attributePhoneNumber);
    attributeList.push(attributeDataFirstName);
    attributeList.push(attributeDataMiddleName);
    attributeList.push(attributeDataFamilyName);

    UserPool.signUp(email, password, attributeList, null, (err, data) => {
      if (
        err ===
        "InvalidPasswordException: Password did not conform with policy: Password must have uppercase characters"
      ) {
        window.alert("Password must have uppercase characters");
      }
      if (
        err ===
        "InvalidPasswordException: Password did not conform with policy: Password not long enough"
      ) {
        window.alert("Password not long enough");
      }
      if (
        err ===
        "InvalidPasswordException: Password did not conform with policy: Password must have uppercase characters"
      ) {
        window.alert("Password must have uppercase characters");
      }
      if (
        err ===
        "InvalidPasswordException: Password did not conform with policy: Password must have symbol characters"
      ) {
        window.alert("Password must have symbol characters");
      }
      if (
        err ===
        "InvalidPasswordException: Password did not conform with policy: Password must have numeric characters"
      ) {
        window.alert("Password must have numeric characters");
      }
      if (err === "UsernameExistsException: User already exists") {
        window.alert("Usuario ya existe");
      }

      if (data !== null) {
        window.alert("registro exitoso. Confirmar email");
        setEmail("");
        setPassword("");
        setPrimerNombre("");
        setSegundoNombre("");
        setApellidos("");
        setTelefono("");
      }
      console.log(err);
      console.log(data);
    });
  };

  const paperStyle = {
    padding: 20,
    height: "60vh",
    width: 280,
    margin: "20px auto",
  };

  const avatarStyle = { backgroundColor: "#005829" };
  const btnstyle = { margin: "8px 0", backgroundColor: "#005829" };
  let varAux = role != undefined ? role.includes("admin") : false;

  return (
    <>
      {loggedIn && varAux ? (
        <>
          <NavBar />
          <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "20px" }}>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="#"
            >
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="#"
            >
              Registrar usuarios
            </Link>
          </Breadcrumbs>

          <Grid>
            <Paper elevation={10} style={paperStyle}>
              <Grid align="center">
                <Avatar style={avatarStyle}>
                  <AddCircleIcon />
                </Avatar>
                <h2>Registar un nuevo usuario</h2>
              </Grid>
              <TextField
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                label="Email"
                placeholder="Ingresar email"
                type="email"
                fullWidth
                required
              />
              <TextField
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                label="Contraseña"
                placeholder="Ingresar contraseña"
                type="password"
                fullWidth
                required
              />
              <TextField
                value={primerNombre}
                onChange={(event) => setPrimerNombre(event.target.value)}
                label="Primer Nombre"
                placeholder="Ingresar Primer Nombre"
                type="text"
                fullWidth
              />
              <TextField
                value={segundoNombre}
                onChange={(event) => setSegundoNombre(event.target.value)}
                label="Segundo Nombre"
                placeholder="Ingresar Segundo Nombre"
                type="text"
                fullWidth
              />
              <TextField
                value={apellidos}
                onChange={(event) => setApellidos(event.target.value)}
                label="Apellidos"
                placeholder="Ingresar Apellidos"
                type="text"
                fullWidth
              />
              <TextField
                value={telefono}
                onChange={(event) => setTelefono(event.target.value)}
                label="Teléfono"
                placeholder="Ingresar Teléfono"
                type="text"
                fullWidth
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                fullWidth
                onClick={onSubmit}
              >
                Registrar
              </Button>
            </Paper>
          </Grid>
        </>
      ) : (
        <Dashboard />
      )}
    </>
  );
};

export default Signup;
