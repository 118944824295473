import {React} from 'react';
import {
	Box,
	Grid, 
	Card, 
	CardContent,
	Typography
} from "@mui/material";

const KpiCard = ({name,icon,value,unit}) => {
	return(
		<>
			<Card>
				<CardContent style={{padding:"0"}}>
					<Grid container>
						<Grid item lg={6} md={6} xs={6}>
							<Box
									sx={{
										padding:"15px 20px",
										width: "100%",
										height: "100%",
										backgroundColor: '#59ad30'
									}}
								>			
									<Grid
										container
										direction="row"
										justifyContent="center"
										alignItems="center"
									>
										<Grid item  lg={3} md={3} xs={3}>
											{icon}
										</Grid>
										<Grid item  lg={8} md={8} xs={8}>
											<Typography variant="subtitle2" sx={{color:"white"}}>
												{name}
											</Typography>
										</Grid>
									</Grid>
							</Box>
						</Grid>

						<Grid item lg={6} md={6} xs={6}>
							<div
								style={{
									padding:"15px 20px",
								}}
							>
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
									spacing={4}
								>
									<Grid item lg={3} md={3} xs={3}>
										<Typography variant="h4" sx={{color:"green", fontWeight:"50px"}}>
											{value}
										</Typography>												
									</Grid>
									<Grid item lg={8} md={8} xs={8}>
										<Typography variant="string" >
											{unit}
										</Typography>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	)
}

export default KpiCard;