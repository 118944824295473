import React, {useState,useEffect} from 'react';
import {
	Grid, 
	Card, 
	CardContent,
	CardHeader,
	Typography,
  CardMedia
} from "@mui/material";
import "moment/locale/es";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  card:{
    height:"180px",
    width:"auto !important"
  },
  titleCard:{
    fontSize:"14.4px !important",
    fontWeight:"bold !important",
    textAlign:"center",
    width:"auto !important"
  },
  subtitleCard:{
    fontSize:"12.4px !important",
    textAlign:"center",
    width:"auto !important"
  },
  contentCard:{
    margin:"0px 10px",
  },
  valueKpi:{
    fontSize:"24px !important",
    fontWeight:"bold !important",
    color: "#59ad30 !important",
    margin:"0px !important",
    padding:"0px !important"
  },
  unitKpi:{
    fontSize:"20px !important",
    color: "#a7a7a7 !important",
    margin:"0px !important",
    padding:"0px !important"
  }
});

const KpiCard2 = ({titulo,subtitulo,valor,unidad,logo,flecha,selected}) => {
  const classes = useStyles();
  return(
    <>
      <Card className={classes.card} sx={{backgroundColor: (selected?"#2f4554":"#fff")}}>  
        <CardContent className={classes.contentCard}>
          <div style = {{height:"105px"}}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item lg={10} md={12} xs={12}>
                  <Typography className={classes.titleCard} sx={{padding:(subtitulo?"0px":"0px 20px"), color:(selected?"#fff":"rgba(0, 0, 0, 0.87)")}}>
                    {titulo}
                  </Typography>
                </Grid>
              </Grid>
              {
                subtitulo && 
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item lg={10} md={12} xs={12}>
                      <Typography className={classes.subtitleCard} sx={{color:(selected?"#fff":"rgba(0, 0, 0, 0.87)")}}>
                        {`(${subtitulo})`}
                      </Typography>
                      </Grid>
                    </Grid>
              }
            </Grid>
          </div>
          <Grid container alignItems="center" justifyContent="center" spacing={1}>
            {!logo?
              <>
                <Grid item>
                  <Typography variant="string" className={classes.valueKpi}>
                    {valor}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="string" className={classes.unitKpi}>
                    {unidad}
                  </Typography>
                </Grid>
                { flecha &&
                  <Grid item>
                    <div style={{
                      width: "0", 
                      height: "0", 
                      borderLeft: "8px solid transparent",
                      borderRight: "8px solid transparent",
                      borderBottom: "12px solid #59ad30"
                    }}/>
                  </Grid>
                }
              </>
            :
              <Grid item>
                <CardMedia
                  component="img"
                  image={logo}
                  alt="logo"
                  style={{ width: "80px"}}
                />        
              </Grid>
            }
          </Grid>

        </CardContent>
      </Card>
    </>
  )
}

export default KpiCard2;